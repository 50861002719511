.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/*table {*/
/*  background: white;*/
/*  border: solid grey 1px;*/
/*  border-collapse: collapse;*/
/*}*/

/*tr {*/
/*  border: none;*/
/*  margin: 0;*/
/*}*/

/*td {*/
/*  padding: 3px 15px;*/
/*}*/

/*th {*/
/*  padding: 3px 15px;*/
/*}*/

/*th {*/
/*  border-bottom: solid black 2px;*/
/*}*/

/*tr:nth-child(even) {background-color: #f2f2f2;}*/

.main-content {
  margin: 20px;
  padding: 2px 20px 20px 20px;
  border-radius: 10px;
  background: #efefef;
}

.red {
  color: white;
  background: darkred;
  border: none;
}

.form_style {
  margin-top: 10px;
}

.form_style>p>label{
  margin-right: 10px;
  font-weight: bold;
}

.hidden {
  display: none;
}

.form_style>p>textarea {
  resize: none;
}

.popup {
  padding: 10px;
  background: white;
  border: black 2px solid;
}

.badpopup {
  padding: 10px;
  background: palevioletred;
  border: black 2px solid;
}

.display-block {
  display: block;
}

.display-inline {
  display: inline;
}

.custom-input-small {
  max-width: 11em !important;
}

.custom-input-med {
  max-width: 30em !important;
}

.small-marg {
  margin-bottom: 5px;
}

ul.tight > li {
  padding-top: 2px !important;
}

ul.tight {
  margin: 0;
}

ul.plain-compact {
  margin: 0 !important;
  padding: 0 !important;
  list-style-type: none;
}

ul.plain-compact > li {
  padding-top: 2px !important;
}

.chat-head {
  padding-right: 0;
  border-right: #efefef 1px solid;
}

.chat-container {
  display: flex;
}

.chat-text {
  width: 100%;
}

.chat-bubble {
  background-color: #efefef;
  border-radius: 0.5rem;
  margin-top: 2rem;
  padding-top: 0.5rem !important;
}

.no-pad {
  padding: 0 !important;
}

.no-marg {
  margin: 0 !important;
}

.small-pad {
  padding: 1rem !important;
}

.behind {
  z-index: 0;
}

.above {
  z-index: 2;
}

.full-height {
  height: 100%;
}

.full-width {
  width: 100%;
}

.box-joiner-container {
  display: block;
  overflow: hidden;
  position: relative;
}

.joiner-line-wrapper {
  display: inline-block;
  height: 100%;
  width: 40px;
  position: absolute;
  left: 0;
  top: 0;
}

.no-circle-joiner {
  height: 20px;
}

.infinite-scroll-component__outerdiv {
  padding: 0 !important;
}

.big-space-below {
  margin-bottom: 100px;
}

.max-width-800 {
  /*width: 800px;*/
}

.error-text {
  color: #d13212 !important;
}

.unauth-container {
  margin: auto;
  padding: 2rem;
  text-align: center;
  max-width: 800px;
}

.align-top {
  vertical-align: top;
}